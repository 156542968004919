.container {
  max-height: calc(100vh - 124px);
}

.head {
  font-size: 24px;
  font-weight: 600;
  padding: 28px 25px;
  line-height: 30px;
  color: #000F29;
  border-bottom: 1px solid #E5E7EA;
}

.content {
  padding: 28px;
  overflow: auto;
  max-height: calc(100vh - 211px);
  max-width: 855px;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 42px;
  color: #808794;
  margin-bottom: 28px;
}

.mb {
  margin-bottom: 28px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 767px) {
  .container {
    max-height: fit-content;
    min-height: 100vh;
    height: 100%;
  }

  .head {
    font-size: 20px;
    line-height: 25.6px;
    padding: 19px 15px;
  }

  .content {
    padding: 15px;
    padding-bottom: 70px;
    max-height: fit-content;
    min-height: 100vh;
  }

  .text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E7EA;
    line-height: 20px;
  }

  .backIcon {
    display: none;
  }

  .buttonContainer {
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
  }
}
