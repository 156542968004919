.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.input {
  width: 0;
  height: 0;
  padding: 0;
  border: none;

  &:focus ~ .code {
    & > .digit.firstEmpty {
      &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 28px;
        top: 14px;
        left: 15.5px;
        background-color: var(--ACCENT_NEW);
        transition-property: visibility;
        animation: blinkCustom 1.7s steps(2, start) infinite;
      }
    }
  }
}

.code {
  display: flex;
  margin-bottom: 16px;
}

.digit {
  position: relative;
  background: #F5F5F5;
  font-size: 24px;
  line-height: 30px;
  padding: 13px 0;
  width: 32px;
  height: 56px;
  border-radius: 6px;
  text-align: center;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.error {
  color: #EA4335;
  margin-top: -11px;
  margin-bottom: 3px;
}

.resendText {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #808794;

  &.active {
    color: var(--ACCENT_NEW);
  }
}

.timer {
  font-weight: 600;
}

@keyframes blinkCustom {
  to {
    visibility: hidden;
  }
}
