.container {
  color: #000F29;
  line-height: 30px;
}

.header {
  margin-bottom: 2px;
  font-size: 24px;
  font-weight: 600;
}

.text {
  margin-bottom: 16px;
  font-size: 16px;
  color: #808794;
}

.dndArea {
  position: relative;
}

.dndHolder {
  position: absolute;
  top: 0;
  bottom: -7px;
  left: -7px;
  right: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 2;
  flex-direction: column;
  color: #808794;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 1px dashed var(--BLACK_30);
    border-radius: 16px;
  }
}

.dndTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 25.6px;
}

.dndText {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.items {
  display: flex;
}

.item {
  position: relative;
  display: flex;
  width: 100px;
  height: 100px;
  margin-right: 16px;
  border-radius: 10px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background: #F1F3F5;
  box-shadow: 0px 0px 5px 0px #00000033;

  &.hoverable {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
}

.itemBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  background: white;
  color: black;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  height: 33px;
  padding: 0 2px;
}

.btn.btn {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.close.close {
  position: absolute;
  top: 4px;
  right: 4px;
}

.error {
  border: 1px solid #EA4335;
  background: rgba(234, 67, 53, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .header {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 4px;
  }

  .text {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }
}
