.container {
  padding: 28px;
  min-width: 550px;
  font-size: 14px;
  line-height: 20px;
  color: #808794;
  max-height: calc(100vh - 124px);
  overflow-y: auto;
}

.head {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000F29;
}

.codeContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 18px;
}

.code {
  display: flex;
  gap: 8px;
  margin-bottom: 18px;
  cursor: pointer;
  margin-right: -32px;
  align-items: center;
  transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
  border-radius: 5px;
  user-select: none;

  &:hover {
    box-shadow: 0px 0px 10px 7px rgba(66, 133, 244, 0.075);
    background-color: rgba(66, 133, 244, 0.075);

    & > .codeIcon {
      color: #4285F4;
    }
  }
}

.codeIcon {
  color: #B3B7BF;
}

.codeHint {
  text-align: center;
  line-height: 14px;
}

.digit {
  padding: 14px 0;
  width: 32px;
  text-align: center;
  background: #F5F5F5;
  color: #000F29;
  font-size: 24px;
  line-height: 28px;
  border-radius: 6px;
}

@media (max-width: 767px) {
  .container {
    padding: 15px;
    min-width: auto;
    max-height: calc(100vh - 86px);
  }

  .head {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 2px;
  }

  .address {
    font-size: 14px;
    line-height: 20px;
  }

  .codeContainer {
    margin-top: 12px;
  }

  .code {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .digit {
    width: 32px;
    height: 40px;
    font-size: 16px;
    line-height: 16px;
    padding: 12px 0;
  }

  .codeIcon.codeIcon {
    display: none;
  }

  .codeHint {
    font-size: 12px;
    line-height: 14px;
  }
}
