.container {
  position: relative;
  letter-spacing: 0.4px;

  .inputWrap:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    left: 0;
    background: var(--ACCENT_NEW);
    transition: width 0.4s;
  }

  &.filled, &.focused {
    .label {
      top: 8px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.focused {
    .inputWrap:before {
      width: 100%;
    }
    .label {
      color: var(--ACCENT_NEW);
    }
  }

  &.erred {
    .input {
      caret-color: #EA4335;
    }
    .label {
      color: #EA4335;
    }
    .inputWrap:before {
      background: #EA4335;
    }
  }
}

.inputWrap {
  position: relative;
}

.input {
  width: 100%;
  background: #F2F3F4;
  border: none;
  border-radius: 4px 4px 0px 0px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 25px 48px 7px 16px;
  caret-color: var(--ACCENT_NEW);
}

.label {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #B3B7BF;
  transition: all .25s;
  pointer-events: none;
}

.rightIcon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: #EA4335;
  margin-top: 4px;
  margin-left: 16px;
  font-size: 12px;
  line-height: 14px;
}
