.container {
  font-size: 14px;
  line-height: 20px;
  color: #808794;

  &.big {
    display: grid;
    gap: 24px;

    .personsHead {
      font-size: 20px;
      padding-bottom: 16px;
      margin-top: 0;
    }
  }
}

.personsHead {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #808794;
  padding-bottom: 12px;
  border-bottom: 1px solid #E5E7EA;
  margin: 24px 0 16px;
}

.person {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  font-size: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.personLeft {
  display: flex;
}

.personInfo {
  margin-left: 13px;
}

.personName {
  color: #000F29;
}

.pointer {
  cursor: pointer;
}

.blue.blue {
  color: #4285F4;
}

.darkGray.darkGray {
  color: #B3B7BF;
}

@media (max-width: 767px) {
  .personsHead {
    margin-top: 18px 0 12px;
    line-height: 20px;
    font-size: 16px;
    padding-bottom: 10px;
  }

  .person {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 20px;
  }

  .personInfo {
    margin-left: 10px;
  }
}
